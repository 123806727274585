.instagramItems {
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    overflow: scroll;
  }
  ::-webkit-scrollbar {
    display: none;
	  scrollbar-width: none;
  }
  .instagramItem {
    -webkit-box-flex: 0;
    /* flex: 0 0 calc(100%/6 - 10px); */
    margin: 10px;
    display: block;
    position: relative;
    max-height: 180px;
    max-width: 180px;
    min-height: 180px;
    min-width: 180px;
    border-radius: 30px;
    overflow: hidden;
  }
  .instagramImg {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: .25s;
    transition: .25s;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .instagramIcon {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    opacity: 0;
  }
  .instagramIcon:hover {
    opacity: 1;
    background-color: #00000061;
  }
  @media (max-width: 480px) {
    
    /* CSS */
    .instagramItem{
      /* flex: 0 0 calc(100%/2 - 10px); */
      max-height: 80px;
      max-width: 80px;
      min-height: 80px;
      min-width: 80px;
      margin: 8px;
    }
    
  }
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    /* CSS */
    .instagramItem {
      /* flex: 0 0 calc(100%/3 - 10px); */
      max-height: 100px;
      max-width: 100px;
      min-height: 100px;
      min-width: 100px;
      margin: 8px;
    }
    
  }

  .errorMessage{
    text-align: center;
    color: red;
    font-size: 18px;
  }



.wrapper {
  position: relative;
}

.placeholder {
  position: absolute;
  padding-bottom: 67%;
  margin-bottom: 4rem;
  width: 100%;
  background: #ffdd28;
  opacity: 1;
  transition: opacity 2s ease;
}

.hidePlaceholder {
  opacity: 0;
}